@charset "UTF-8";
/*----------------------------------------

	Theme Name: Goodwin HTML5 eCommerce Template
	Author: BigSteps
	Author URI: http://themeforest.net/user/bigsteps
	Version: 1.0.0

-----------------------------------------*/
/*----------------------------------------

[Table of contents]

1. Main Structure
2. Typography
    2.1. Basic elements
    2.2. Buttons
    2.3. Form elements
    2.4. Subscribe Form
    2.5. Tags
    2.6. Tooltip
    2.7. Breadcrumbs
    2.8. Tabs
    2.9. Slick Slider Custom
3. Menu
    3.1. Megamenu
    3.2. Mobile Menu
4. Header
    4.1. Promo Topline
    4.2. Header Structure
5. Footer
6. Main Slider
7. Banners
8. Product and Category Page
8.1. Product Card
8.2. Product Single Slider
8.3. Product Banner
8.4. Product Page
8.5. Category Page
8.6. Product Swatches
8.7. Product Prices
8.8. CountDown
9. Collections
10. Cart Table Page
11. Modals
12. Brand Block
    12.1. Brand Grid/Carousel
    12.2. Brand Product
13. Shop Features Blocks
14. Card image & text
15. Big Single Slider (with price table)
16. Testimonials
17. Instagram
18. Blog Pages
19. About page
    19.1 Timeline
    19.2  Person card
20. Gallery page
21. FAQ
22. Browsers fix


---------------------------------------*/

/*  1. Main Structure  */
@import "components/loader";
@import "components/structure";

/*  2. Typography  */
    /*  2.1. Basic elements  */
    @import "components/typography";
    /*  2.2. Buttons  */
    @import "components/buttons";
    /*  2.3. Form elements  */
    @import "components/form";
    /*  2.4. Subscribe Form  */
    @import "components/subscribe-form";
    /*  2.5. Tags  */
    @import "components/tags";
    /*  2.6. Tooltip  */
    @import "components/tooltip";
    /*  2.7. Breadcrumbs  */
    @import "components/breadcrumbs";
    /*  2.8. Tabs  */
    @import "components/tabs";
    @import "components/category-tab-content";
    /*  2.9. Slick Slider Custom */
    @import "components/slick";

/*  3. Menu  */
    /*  3.1. Megamenu  */
    @import "components/megamenu";
    /*  3.2. Mobile Menu  */
    @import "components/mobilemenu";
    @import "components/mobilemenu-push";

/*  4. Header  */
    /*  4.1. Promo Topline  */
    @import "components/promo-topline";
    /*  4.2. Header Structure  */
    @import "components/header";
    @import "components/header2";
    @import "components/header3";
    @import "components/header4";
    @import "components/header5";
    @import "components/header6";
    @import "components/header7";
    @import "components/header8";
    @import "components/header11";

/*  5. Footer  */
@import "components/footer";

/* 6. Main Slider  */
@import "components/bnslider";

/* 7. Banners  */
@import "components/banners";
@import "components/comingsoon-banner";

/*  8. Product and Category Page  */
    /*  8.1. Product Card  */
    @import "components/product";
    /*  8.2. Product Single Slider  */
    @import "components/product-slider-single";
    /*  8.3. Product Banner  */
    @import "components/product-banner";
    /*  8.4. Product Page  */
    @import "components/product-page";
    /*  8.5. Category Page  */
    @import "components/category-filter";
    /*  8.6. Product Swatches  */
    @import "components/color-swatch";
    @import "components/size-swatch";
    @import "components/colors";
    /*  8.7. Product Prices  */
    @import "components/prices";
    /*  8.8. CountDown  */
    @import "components/countdown";
    /*  8.9. Compare  */
    @import "components/compare-page";

/*  9. Collections  */
@import "components/collections-carousel";
@import "components/collections";
@import "components/categories";

/*  10. Cart Table Page */
@import "components/cart-table";

/*  11. Modals  */
@import "components/modal";

/*  12. Brand Block */
    /*  12.1. Brand Grid/Carousel  */
    @import "components/brand-carousel";
    /*  12.2. Brand Product  */
    @import "components/brand-product";
    @import "components/inside-carousel";

/*  13. Shop Features Blocks  */
@import "components/shop-features";

/*  14. Card image & text  */
@import "components/card-imgtext";

/*  15. Big Single Slider (with price table)  */
@import "components/big-carousel";

/*  16. Testimonials  */
@import "components/testimonials";

/*  17. Instagram  */
@import "components/instagram";

/*  18. Blog Pages  */
@import "components/post";
@import "components/reviews";

/*  19. About page
    /*  19.1 Timeline  */
    @import "components/timeline";
    /*  19.2  Person card  */
    @import "components/person";

/*  20. Gallery page  */
@import "components/gallery";

/*  21. FAQ  */
@import "components/faq";

/*  22. Browsers fix  */
@import "components/cross-browser";